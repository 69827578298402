import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import wxAuth from '../views/wxAuth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'index',
    component: Index
  },
  {
    path: '/wxAuth',
    name: 'wxAuth',
    component: wxAuth
  },
  {
    path: '/flowCard',
    name: 'flowCard',
    component: () => import('../views/flow/flowCard')
  },
  {
    path: '/flowActivation',
    name: 'flowActivation',
    component: () => import('../views/flow/flowActivation.vue')
  },
  {
    path: '/Appfeedback',
    name: 'Appfeedback',
    component: () => import('../views/flow/Appfeedback.vue')
  },
  {
    path: '/flowBuy',
    name: 'flowBuy',
    component: () => import('../views/flow/flowBuy.vue')
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/flow/Privacy.vue')
  },
  {
    path: '/flowLoading',
    name: 'flowLoading',
    component: () => import('../views/flow/flowLoading.vue')
  },
  {
    path: '/indicate',
    name: 'indicate',
    component: () => import('../views/flow/indicate.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/flow/service.vue')
  },
  {
    path: '/Mobiledevice',
    name: 'Mobiledevice',
    component: () => import('../views/Mobile/Mobiledevice.vue')
  },
  {
    path: '/Mobiledpay',
    name: 'Mobiledpay',
    component: () => import('../views/Mobile/Mobiledpay.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/flowShare/share.vue')
  },
  {
    path: '/Adaptation',
    name: 'Adaptation',
    component: () => import('../views/flowShare/Adaptation.vue')
  },
  {
    path: '/videoPlay',
    name: 'videoPlay',
    component: () => import('../views/videoPlay/videoPlay.vue')
  },
  {
    path: '/myUser',
    name: 'myUser',
    component: () => import('../views/myUser/myUser.vue')
  },

  {
    path: '/flowList',
    name: 'flowList',
    component: () => import('../views/flow/flowList.vue')
  },
  {
    path: '/AppleVideo',
    name: 'AppleVideo',
    component: () => import('../views/Mobile/AppleVideo.vue')
  },
  {
    path: '/AndroidVideo',
    name: 'AppleVideo',
    component: () => import('../views/Mobile/AndroidVideo.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/Mobile/feedback.vue')
  },
  {
    path: '/Realname',
    name: 'Realname',
    component: () => import('../views/Mobile/Realname.vue')
  },
  {
    path: '/against',
    name: 'against',
    component: () => import('../views/Mobile/against.vue')
  },
  {
    path: '/listed',
    name: 'listed',
    component: () => import('../views/myUser/listed.vue')
  },
  {
    path: '/one',
    name: 'one',
    component: () => import('../views/ceshi/one.vue')
  },
  {
    path: '/two',
    name: 'two',
    component: () => import('../views/ceshi/two.vue')
  },
  {
    path: '/informationShow',
    name: 'informationShow',
    component: () => import('../views/agent/informationShow.vue')
  },
  {
    path: '/signStatistics',
    name: 'signStatistics',
    component: () => import('../views/agent/signStatistics.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/agent/login.vue')
  },
  {
    path: '/signal',
    name: 'signal',
    component: () => import('../views/myUser/signal.vue')
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: () => import('../views/myUser/myOrder.vue')
  },
  {
    path: '/videoSlide',
    name: 'videoSlide',
    component: () => import('../views/videoSlide/videoSlide.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('../views/limit/purchase.vue')
  },
  // 收货地址
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('../views/limit/delivery.vue')
  },
  // 新增收货地址
  {
    path: '/addDelivery',
    name: 'addDelivery',
    component: () => import('../views/limit/addDelivery.vue')
  },
  //扫码识别
  {
    path: '/qRCode',
    name: 'qRCode',
    component: () => import('../views/flowShare/qRCode.vue')
  },
  //短剧视频
   {
    path: '/shortPlay',
    name: 'shortPlay',
    component: () => import('../views/videoPlay/shortPlay.vue')
  },
  // 短剧分享页面
   {
    path: '/shortShare',
    name: 'shortShare',
    component: () => import('../views/videoPlay/shortShare.vue')
  },
   // 短剧支付
   {
    path: '/shortPay',
    name: 'shortPay',
    component: () => import('../views/videoPlay/shortPay.vue')
  },
  //安卓短剧下载
    {
    path: '/AndroidDownload',
    name: 'AndroidDownload',
    component: () => import('../views/videoPlay/AndroidDownload.vue')
  },
  // 新的我的页面
  {
    path: '/myPage',
    name: 'myPage',
    component: () => import('../views/newsPage/myPage.vue')
  },
  // 代理的签到页面
   {
    path: '/agentVideoPlay',
    name: 'agentVideoPlay',
    component: () => import('../views/videoPlay/agentVideoPlay.vue')
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
})

export default router
